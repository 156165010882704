import React from "react"
// import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import { PageLayout } from "../components/layouts"
// import Image from "../components/image"
import SEO from "../components/seo"

import TextBlock from "../components/textBlock"
import TextImageBlock from "../components/textImageBlock"
import PageWrapper from "../components/pageWrapper"

import HeroSlider from "../components/heroSlider"
import ParallaxSection from "../components/parallaxSection"

import Blockquote from "../components/blockquote"

import {
  ScheduleVisitButton,
  RegisterForWebinarButton,
} from "../components/buttons"

import { QuotePanel } from "../components/quotes"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      parallax: file(relativePath: { eq: "AdobeStock_250630318.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <PageLayout>
      <SEO
        title="Long Term Care Insurance Atlanta GA | The Long Term Care Planning Group"
        ogDescription="Long Term Care Insurance is critical to protecting your investments and ensuring you are covered for a number of care options at the end of life."
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Long Term Care Insurance</h1>
            <h4>Based in Atlanta, Georgia. Serving the Nation.</h4>
            <p>
Based in Atlanta, Georgia. Serving the Nation.
The Long Term Care Planning Group has focused on long term care planning since 2001. We have relationships with all the major carriers in the long term care marketplace. We work with only A.M. Best Rating A+ carriers to find the best match based on your medical history. We've been fortunate to place thousands of clients all over the United States.
          </p>
          <p>
Welcome to our website where we share our knowledge, experience, and approach. As either an individual or an employer, long term care education is foundational. It’s important to understand the history of long term care insurance, how premiums are set, and your venue options.
          </p>
          <p>
A great place to start is to register for our free webinar. It's only 20 minutes and provides a strong overview of how long term care insurance and care works.
          </p>
          <RegisterForWebinarButton />
          </div>
        }
        textRight={
          <div>
            <img src="/images/AdobeStock_159276199.jpg" alt="Long Term Care Insurance Atlanta GA" />
            <Blockquote>Our free webinar covers important details you need to know about long term care planning. Our president, Corey Rieck, explains why you should not rely upon government programs or only your family members for long term care.</Blockquote>
          </div>
        }
      />
      <ParallaxSection
        src={`/images/parallax/AdobeStock_186759250.jpg`}
        height={300}
      />
      <QuotePanel text="Hello World" person="John Doe" />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>
              <a href="/long-term-health-care/">
                What Is Long Term Health Care?
              </a>
            </h2>
            <p>One way to receive long term health care benefits is due to physical impairment that limits a person’s activities of daily living. The other way a person can potentially collect benefits from an insurance carrier for long term health care is via cognitive impairment. Skilled care is delivered when there’s an expectation of improvement and is delivered by highly trained and licensed medical professionals. Long term care is used when a person is in a chronic state and they are not expected to get better. Skilled care is typically covered by health insurance and is going to help you get better. Long term care is delivered by non-skilled caregivers when your condition is not going to improve. A person with long term care insurance can actually choose from four venues to get long term care depending upon how much help they need.</p>
            <h2>
              <a href="/long-term-care-planning/">
                Individual Long Term Care Planning Process
              </a>
            </h2>
            <p>Long term care planning protects your IRAs, 40lks is an investment so they can provide as you’ve intended in the event that you need long term care assistance. The Long Term Care Planning Group helps people understand what long term care is and, perhaps more importantly, what it isn’t. We optimize plans individually based on the client’s health, funding and planning needs. Every plan can be catered specifically to that person. We walk you through a confidential step-by-step process including: discovery, exploration, review with LTC carriers and securing your application. We’ll support next steps including underwriting interviews and exams. Then we’ll explain the carrier’s underwriting decision and deliver your policy once approved. Then we’ll be here for your continued support.</p>
            <ScheduleVisitButton />
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/AdobeStock_225477126.jpg"
              alt="Long Term Care Insurance Atlanta GA Specialist"
            />
            <h2>
              <a href="/long-term-care-insurance-specialist/">
                Why Use A Long Term Care Insurance Specialist?
              </a>
            </h2>
            <p>We take the time to be sure you understand what long term care is and what it isn’t. We’ll discuss options most amenable to your medical history. If there is no medical history, you will likely have options. If there is medical history, a second strategy will be required for us to find a carrier more amenable to your medical history. Our clients find our confidential and consultative approach we take reassuring.</p>
          </div>
        }
      />
      <HeroSlider
        slides={[
          {
            title: "What Is Long Term Care?",
            text: (
              <p>
                Long term care is a special kind of help a person may need because of a prolonged illness or long term disability.
              </p>
            ),
            image: "/images/slider/AdobeStock_298891528.jpg",
            extra: "Learn More",
            link:
              "/long-term-health-care/",
          },
          {
            title: "Long Term Care Planning",
            text: (
              <p>
                Learn how long term care <b>planning</b> can protect your retirement assets so they can be used the way you intend. 
              </p>
            ),
            image: "/images/slider/AdobeStock_62351947.jpg",
            extra: "Learn More",
            link:
              "/long-term-care-planning/",
          },
          {
            title: "Why Use A Specialist?",
            text: (
              <p>
                A long term care insurance <b>specialist</b> will have working relationships with all the major carriers.
              </p>
            ),
            image: "/images/slider/AdobeStock_84431607.jpg",
            extra: "Learn More",
            link:
              "/long-term-care-insurance-specialist/",
          },
          {
            title: "Get A Quote",
            text: (
              <p>
                Get your Long Term Care Insurance Quote. Just answer a few, quick questions. Corey Rieck and his team will contact you with information and education.
              </p>
            ),
            image: "/images/slider/AdobeStock_118301242.jpg",
            extra: "Learn More",
            link:
              "/long-term-care-insurance-quote/",
          },
          {
            title: "Employee Benefits",
            text: (
              <p>
Our key deliverables for companies include education and a step-by-step consultative approach; enrollment support; and awareness campaigns. There are tax incentives and options on how premiums are paid.
              </p>
            ),
            image: "/images/slider/AdobeStock_241586114.jpg",
            extra: "Learn More",
            link:
              "/long-term-care-group-insurance/",
          },
        ]}
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>
              <a href="/long-term-care-group-insurance/">
                Long Term Care Group Insurance
              </a>
            </h2>
            <p>At The Long Term Care Planning Group, our exclusive focus on long term care planning since 2001. Clients appreciate working with us to provide their long term care group insurance for several reasons. Our key deliverables for companies include education and a step-by-step consultative approach; enrollment support; and awareness campaigns. There are tax incentives and options on how premiums are paid. We only work with the A.M. Best Rating A+ insurance providers like Transamerica and Chubb.</p>
            <ScheduleVisitButton />
          </div>
        }
        textRight={
          <div>
            <img src="/images/AdobeStock_246952945.jpg" alt="Long Term Care Insurance Atlanta GA" />
          </div>
        }
      />
      <TextImageBlock
        image={
          <img
            src="/images/AdobeStock_175442176.jpg"
            alt="Long Term Care Insurance Atlanta GA Exclusive Focus"
            className="right-point"
          />
        }
        imageGrow
        textPadded
        text={
          <div>
            <h2>
              <a href="/long-term-care-insurance-companies/">
                Long Term Care Insurance Companies And Strategy Overview
              </a>
            </h2>
            <p>Money pays for it, health buys it. If a client cannot successfully navigate underwriting, they may not be able to secure long term care coverage on an individual basis and may have to contemplate an alternative route to manage potential long term care expenses. If there is no medical history, there will likely be several options. If there is medical history, the options may be more limited. Each long term care insurance company may consider certain medical history differently; and it is important to consider working with a long term care specialist that is both carrier and instrument neutral.</p>
          </div>
        }
      />
      <TextImageBlock
        image={
          <img
            src="/images/AdobeStock_262943311.jpg"
            alt="Long Term Care Insurance Atlanta Cost"
            className="left-point"
          />
        }
        imageGrow
        imageRight
        textPadded
        text={
          <div>
            <h2>
              <a href="/long-term-care-insurance-cost/">
                Long Term Care Insurance Cost
              </a>
            </h2>
            <p>The Genworth Cost of Long Term Care Survey Tool helps estimate median costs in major cities and all 50 states. This tool is available to everyone. Learn how we use this tool to help you understand the long term care insurance cost anywhere in the United States. Another important use of this tool is to calculate future long term care insurance costs based on Genworth’s long historical data collection and economic predications. If you purchase long term care insurance, it will provide a stream of income to cover care related expenses in the location and setting of your choosing. If you don’t purchase long term care, people end up using funds from their 401k, their IRAs and the equity of their house to defray these costs.</p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>
              <a href="/long-term-care-facts-myths/">
                Long Term Care Facts And Myths
              </a>
            </h2>
            <p>Most of us will face limitations of our daily activities or due to a permanent disability as we age. Professional medical care at home or in a nursing home is expensive. And these costs typically increase each year. There are many misconceptions and myths regarding long term care—what it is and why it isn’t—and options on where to receive long term care. Better understanding the facts around long term care will help ensure you make the right choices for your family and financial situation.</p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/AdobeStock_135904252.jpg" alt="Long Term Care Insurance Atlanta Facts and Myths" />
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
